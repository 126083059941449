import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet d’olor a fusta humida i amb un capell de fins a 10 cm de diàmetre primer tancat i convex per acabar irregularment obert, amb el marge enrotllat cap a dins i alçat amb l’edat. Aquest és de color marró rogenc, més o menys pàl·lid i a vegades presenta taques de color rovell. Les làmines són adherents i blanquinoses tacades amb l’edat. Les espores són blanques en massa, el·lipsoidals, de 4-7 x 3-5 micres. El peu és fusiforme, més o menys aplanat, fibrós i amb solcs de color semblant al capell, més clar a la part superior i més fosc a la base.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      